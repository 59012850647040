import { action, observable, toJS } from 'mobx';
import Validator from 'validatorjs';
import { applicationStore } from 'shared/stores';
import moment from 'moment';
import rest from 'shared/lib/rest';
import BaseForm from './base-form';

export const instruments = { kryptor_compact_plus: { label: 'Kryptor Compact PLUS' }, kryptor_gold: { label: 'Kryptor GOLD' } };
export const products = { kryptor: 'Kryptor SW v11 / Chat LIS', fastscreenplus: 'Fast Screen PLUS' };
export const modules = { pnst: 'All countries except USA', pns: 'USA only' };
export const licenseTypes = { demo: 'Demo', final: 'Final' };
export const licenseDuration = { 1: '1 Year', 5: '5 Years' };

Validator.register('sn_kryptor_plus', value => value.match(/^([A-Za-z0-9]{5})(,(?!\1)([A-Za-z0-9]{5})){0,5}$/),
  'The :attribute value is not in the correct format.');

Validator.register('sn_kryptor_gold', value => value.match(/^([A-Za-z0-9]{6})(,(?!\1)([A-Za-z0-9]{6})){0,5}$/),
  'The :attribute value is not in the correct format.');

class CmtLicenseForm extends BaseForm {
  @observable username = '';
  saveButtonUsesPristine = true;
  renewMode = false;
  @observable devModeAllowed = false;

  @observable
  fields = {
    user_id: {
      value: '',
      error: null,
      disabled: true,
      label: 'User',
      rule: 'required',
    },
    institution: {
      value: '',
      error: null,
      rule: 'required|max:100',
      required: true,
      label: 'Institution Name',
      placeholder: '',
    },
    city: {
      value: '',
      error: null,
      rule: 'required|max:25',
      required: true,
      label: 'City',
      placeholder: '',
    },
    country_id: {
      value: null,
      error: null,
      rule: 'required',
      required: true,
      label: 'Country',
      placeholder: 'Select a country',
    },
    instruments: {
      value: [],
      error: null,
      rule: 'required|array',
      required: true,
      label: 'Instruments',
      placeholder: '',
    },
    kryptor_compact_plus: {
      value: '',
      error: null,
      rule: 'sn_kryptor_plus',
      required: true,
      label: 'Serial Number for Kryptor Compact +',
      placeholder: 'up to 6 serial numbers, comma separated',
    },
    kryptor_gold: {
      value: '',
      error: null,
      rule: 'sn_kryptor_gold',
      required: true,
      label: 'Serial Number for Kryptor GOLD',
      placeholder: 'up to 6 serial numbers, comma separated',
    },
    product: {
      value: null,
      error: null,
      rule: 'required|string',
      required: true,
      label: 'Product',
      placeholder: '',
    },
    license_type: {
      value: null,
      error: null,
      rule: 'required',
      label: 'Installation Type',
      required: true,
      placeholder: '',
    },
    installation_number: {
      value: '',
      error: null,
      rule: 'required|max:100',
      label: 'Installation Number',
      required: true,
      placeholder: '',
    },
    order_date: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Date',
      placeholder: '',
    },
    modules: {
      value: null,
      error: null,
      rule: 'required_if:product,fastscreenplus',
      required: true,
      label: 'License Type',
      placeholder: '',
      hideWhenDisabled: true,
      block: true,
    },
    purchase_order_number: {
      value: '',
      error: null,
      rule: 'max:20',
      label: 'Purchase Order Number',
      placeholder: '',
      hideWhenDisabled: true,
    },
    comment: {
      value: '',
      error: null,
      rule: 'max:500',
      multiline: true,
      rows: 8,
      label: 'Comment',
      hideWhenDisabled: true,
    },
    price_agreement: {
      value: false,
      error: null,
      rule: 'boolean',
      label: 'Check if Price agreement available',
      placeholder: '',
      hideWhenDisabled: true,
    },
    price_agreement_file: {
      value: null,
      error: null,
      rule: 'string',
      required: true,
      label: 'Please attach the price agreement',
      disabled: true,
      hideWhenDisabled: true,
    },
    invoice: {
      value: false,
      error: null,
      rule: 'accepted',
      required: true,
      label: 'If you check this box, you agree that an invoice will be generated',
      hideWhenDisabled: true,
    },
    email_me: {
      value: true,
      error: null,
      rule: 'boolean',
      label: 'Email me the generated license',
      hideWhenDisabled: true,
    },
  }

  @action
  onFieldChangeInstruments = (e) => {
    this.onFieldChange(e);
    this.fields.kryptor_compact_plus.rule = this.fields.instruments.value.includes('kryptor_compact_plus') ? 'required|sn_kryptor_plus' : 'sn_kryptor_plus';
    this.fields.kryptor_gold.rule = this.fields.instruments.value.includes('kryptor_gold') ? 'required|sn_kryptor_gold' : 'sn_kryptor_gold';
    // clear serial number fields
    if (e.target.value === 'kryptor_compact_plus' && !this.fields.instruments.value.includes('kryptor_compact_plus')) {
      this.fields.kryptor_compact_plus.value = '';
      this.fields.kryptor_compact_plus.error = null;
    } else if (e.target.value === 'kryptor_gold' && !this.fields.instruments.value.includes('kryptor_gold')) {
      this.fields.kryptor_gold.value = '';
      this.fields.kryptor_gold.error = null;
    }
    // this violates our separation of logic and UI!
    if (e.target.value === 'kryptor_compact_plus' && this.fields.instruments.value.includes('kryptor_compact_plus')) {
      window.setTimeout(() => {
        document.getElementById('kryptor_compact_plus-input').focus();
      }, 0);
    } else if (e.target.value === 'kryptor_gold' && this.fields.instruments.value.includes('kryptor_gold')) {
      window.setTimeout(() => {
        document.getElementById('kryptor_gold-input').focus();
      }, 0);
    }
  }

  @action
  onFieldChangeProduct = (e) => {
    this.onFieldChange(e);
    if (this.fields.product.value === 'fastscreenplus') {
      this.fields.modules.disabled = false;
      this.fields.purchase_order_number.disabled = false;
      this.fields.price_agreement.disabled = false;
      this.fields.price_agreement_file.disabled = !this.fields.price_agreement_file.value;
    } else {
      this.fields.modules.value = null;
      this.fields.modules.disabled = true;
      this.fields.purchase_order_number.value = '';
      this.fields.purchase_order_number.disabled = true;
      this.fields.price_agreement.value = false;
      this.fields.price_agreement.disabled = true;
      this.fields.price_agreement_file.value = null;
      this.fields.price_agreement_file.disabled = true;
    }
    if (this.fields.license_type.value) {
      this.onFieldChangeType({ target: { id: 'license_type', value: this.fields.license_type.value } });
    }
  }

  @action
  onFieldChangeType = (e) => {
    this.onFieldChange(e);
  }

  @action onFieldChangePriceAgreement = (e) => {
    this.onFieldChange(e);
    if (this.fields.price_agreement.value) {
      this.fields.price_agreement_file.disabled = false;
      this.fields.price_agreement_file.required = true;
      this.fields.price_agreement_file.rule = 'required';
    } else {
      this.fields.price_agreement_file.disabled = true;
      this.fields.price_agreement_file.required = false;
      this.fields.price_agreement_file.rule = 'string';
      this.fields.price_agreement_file.error = null;
    }
  }

  @action setDefaultValues = () => {
    if (this.renewMode) return;
    this.fields.order_date.value = moment().format(moment.HTML5_FMT.DATE);
    this.onFieldChange({
      target: {
        id: 'order_date',
        value: this.fields.order_date.value,
      }
    });

    if (this.fields.user_id.value.length === 0) {
      this.fields.user_id.value = applicationStore.appUser.get('id');
      this.username = '';
    }
    this.pristine = true;
  }

  @action clearValues = () => {
    const copy = new CmtLicenseForm();
    this.fields = toJS(copy.fields);
    this.pristine = true;
    this.renewMode = false;
  }

  @action fillFromExisting = (license) => {
    this.renewMode = true;
    this.fields.user_id.value = applicationStore.appUser.get('id');
    this.username = '';
    this.fields.institution.value = license.institution;
    this.fields.institution.disabled = true;
    this.fields.city.value = license.city;
    this.fields.city.disabled = true;
    this.fields.country_id.value = license.countryId;
    this.fields.country_id.disabled = true;
    const instrumentList = [];
    if (license.kryptorCompactPlus.length) instrumentList.push('kryptor_compact_plus');
    if (license.kryptorGold.length) instrumentList.push('kryptor_gold');
    this.fields.instruments.value = instrumentList;
    this.fields.kryptor_compact_plus.value = license.kryptorCompactPlus;
    this.fields.kryptor_gold.value = license.kryptorGold;
    this.fields.installation_number.value = license.installationNumber;
    this.fields.installation_number.disabled = true;
    this.fields.modules.value = license.modules[0];
    this.fields.comment.value = license.comment;
    this.onFieldChangeProduct({ target: { id: 'product', value: license.product } });
    this.fields.product.disabled = true;
    this.onFieldChangeType({ target: { id: 'license_type', value: 'final' } });
    this.fields.license_type.disabled = true;
    this.fields.order_date.value = moment().format(moment.HTML5_FMT.DATE);
    this.pristine = false;
  }

  @action validatePage1 = () => {
    // force validation of all fields on page 1
    this.onFieldChange({ target: { id: 'institution', value: this.fields.institution.value } });
    this.onFieldChange({ target: { id: 'city', value: this.fields.city.value } });
    this.onFieldChange({ target: { id: 'country_id', value: this.fields.country_id.value } });
    this.onFieldChange({ target: { id: 'instruments', value: this.fields.instruments.value } });
    this.onFieldChange({ target: { id: 'kryptor_compact_plus', value: this.fields.kryptor_compact_plus.value } });
    this.onFieldChange({ target: { id: 'kryptor_gold', value: this.fields.kryptor_gold.value } });
    return (
      !this.fields.institution.error
      && !this.fields.city.error
      && !this.fields.country_id.error
      && !this.fields.instruments.error
      && !this.fields.kryptor_compact_plus.error
      && !this.fields.kryptor_gold.error
    );
  }

  @action create = async () => {
    // eslint-disable-next-line guard-for-in
    for (const field in this.fields) {
      this.onFieldChange({
        target: {
          id: field,
          value: this.fields[field].value,
        } });
    }
    if (!this.isValid) return false;

    this.saveInProgess = true;
    let success = false;
    const formData = new FormData();
    for (const field in this.fields) {
      if (field === 'user_id' && this.fields.user_id.value === applicationStore.appUser.get('id')) continue;
      if (field === 'order_date' && !this.devModeAllowed) continue;
      if (field === 'modules') {
        formData.append(field, this.fields[field].value ? [this.fields[field].value] : []); // was originally multiselect, so we expect an array
        continue;
      }
      formData.append(field, this.fields[field].value);
    }
    try {
      const result = await rest.post('/cmt_license', formData);
      if (!result.data.success) {
        applicationStore.addNotification(result.data.msg);
      } else {
        success = true;
        await applicationStore.updateWatermelonDatabase();
      }
    } catch (e) {
      applicationStore.addNotification(e.response.data.msg);
      console.log(e.response, e.request, e.message, e.config);
    }
    this.saveInProgess = false;
    return success;
  }
}

export default new CmtLicenseForm();
