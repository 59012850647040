import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import Settings from 'web/components/settings/settings';
import Materials from 'web/components/materials/materials';
import Presentation from 'web/components/presentation/presentation';
import Dashboard from 'web/components/dashboard/dashboard';
import NewPresentation from 'web/components/create-presentation/new-presentation';
import DefaultSidebar from 'web/components/default-sidebar';
import { applicationStore, routing } from 'shared/stores';
import { simappConstants } from 'shared/lib/theme';
import presentationSidebar from 'web/components/presentation/presentation-sidebar';
import CopeptinStart from 'web/components/copeptin/copeptin-start';
import CopeptinPopulation from 'web/components/copeptin/copeptin-population';
import CopeptinInputs from 'web/components/copeptin/copeptin-inputs';
import CopeptinInputsPercent from 'web/components/copeptin/copeptin-inputs-percent';
import CopeptinResults from 'web/components/copeptin/copeptin-results';
import CopeptinInfo from 'web/components/copeptin/copeptin-info';
import CopeptinReferences from 'web/components/copeptin/copeptin-references';
import PctStart from 'web/components/pct/pct-start';
import PctReferences from 'web/components/pct/pct-references';
import PctPopulation from 'web/components/pct/pct-population';
import PctHealthcare from 'web/components/pct/pct-healthcare';
import PctClinicalEffect from 'web/components/pct/pct-clinicaleffect';
import PctResults from 'web/components/pct/pct-results';
import pctInfo from 'web/components/pct/pct-info';
import CmtCreateLicense from './cmt/create-license';
import CmtLicenseList from './cmt/license-list';
import CmtUserGroupList from './cmt/user-group-list';
import CmtUserList from './cmt/user-list';
import CmtCreateCredentials from './cmt/create-credentials';
import CmtScreen from './cmt/cmt-screen';
import ServiceScreen from './service/service-screen';
import HEModelScreen from './he-model/he-model-screen';

export const history = syncHistoryWithStore(createBrowserHistory(), routing);

const styles = theme => ({
  root: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    marginLeft: simappConstants.sidebarMinWidth,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.presentationmode': {
      marginLeft: 0
    }
  }
});

// each entry consists of: path, component, exact (bool)
const thermoOnlyRoutes = [
];
const defaultRoutes = [
  ['/', Dashboard, true],
  ['/settings', Settings],
];
const heModelRoutes = [
  ['/he-models', HEModelScreen, true],
];
const pctRoutes = [
  ['/he-models/pct-calculator', PctStart, true],
  ['/he-models/pct-calculator/references', PctReferences],
  ['/he-models/pct-calculator/population', PctPopulation],
  ['/he-models/pct-calculator/healthcare', PctHealthcare],
  ['/he-models/pct-calculator/clinicaleffect', PctClinicalEffect],
  ['/he-models/pct-calculator/clinicaleffect-lrti', PctClinicalEffect],
  ['/he-models/pct-calculator/clinicaleffect-sepsis', PctClinicalEffect],
  ['/he-models/pct-calculator/pct-algorithm', pctInfo],
  ['/he-models/pct-calculator/pct-algorithm-sepsis', pctInfo],
  ['/he-models/pct-calculator/pct-algorithm-lrti', pctInfo],
  ['/he-models/pct-calculator/results', PctResults],
];
const copeptinRoutes = [
  ['/he-models/copeptin-calculator', CopeptinStart, true],
  ['/he-models/copeptin-calculator/copeptin-algorithm', CopeptinInfo],
  ['/he-models/copeptin-calculator/copeptin-references', CopeptinReferences],
  ['/he-models/copeptin-calculator/population', CopeptinPopulation],
  ['/he-models/copeptin-calculator/inputs', CopeptinInputs],
  ['/he-models/copeptin-calculator/inputs-percent', CopeptinInputsPercent],
  ['/he-models/copeptin-calculator/results', CopeptinResults],
];
const materialsRoutes = [
  ['/materials', Materials],
  ['/presentation-materials', Materials],
  ['/presentation', Presentation, true],
  ['/presentation/:uid', Presentation],
];
const presentationRoutes = [
  ['/create-presentation', NewPresentation, true],
  ['/create-presentation/:uid', NewPresentation],
];
const serviceRoutes = [
  ['/services', ServiceScreen, true],
];
const softwareRoutes = [
  ['/services/software', Materials],
  ['/presentation-software', Materials],
  ['/presentation', Presentation, true],
  ['/presentation/:uid', Presentation],
];
const cmtRoutes = [
  ['/services/cmt', CmtScreen, true],
];
const cmtCredentialsRoutes = [
  ['/cmt/credentials', CmtCreateCredentials, true],
];
const cmtLicensesRoutes = [
  ['/cmt/licenselist', CmtLicenseList],
  ['/cmt/license', CmtCreateLicense, true],
];
const cmtSuperUserRoutes = [
  ['/cmt/credentials/:userId', CmtCreateCredentials],
  ['/cmt/userlist', CmtUserList],
  ['/cmt/grouplist', CmtUserGroupList],
  ['/cmt/license/:userId', CmtCreateLicense],
];

@observer
class MainRouter extends Component {
  render() {
    const { classes } = this.props;
    const path = routing.location.pathname.split('/');

    // if logged in but unknown user, always force settings page
    if (applicationStore.userLoaded === false) {
      return <Settings />;
    }

    // if we are in the presentaion area, we need to add the current presentation to the list of active
    // FIXME: This shouldn't be in render() and we need to check if the uid is valid
    applicationStore.setPresentationMode(path[1] === 'presentation');

    const presentationModeClass = applicationStore.presentationMode ? 'presentationmode' : '';

    return (
      <Router history={history}>
        <Fragment>
          <Switch>
            <Route path="/presentation" component={presentationSidebar} />
            <Route path="/presentation-materials" component={presentationSidebar} />
            <Route path="/presentation-software" component={presentationSidebar} />
            <Route path="/" component={DefaultSidebar} />
          </Switch>
          <div className={`${classes.root} ${presentationModeClass}`} id="MainRouter">
            <Switch>
              {!applicationStore.isDistributor && (
                thermoOnlyRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessMaterials && (
                materialsRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessPresentations && (
                presentationRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessHeModels && (
                heModelRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessPct && (
                pctRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessCopeptin && (
                copeptinRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessServices && (
                serviceRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessSoftware && (
                softwareRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {applicationStore.canAccessCmt && (
                cmtRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              { applicationStore.permissionList && applicationStore.permissionList.includes('cmt_read_user_management') && (
                cmtSuperUserRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              { applicationStore.permissionList && applicationStore.permissionList.includes('cmt_create_credentials') && (
                cmtCredentialsRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              { applicationStore.permissionList
                && (applicationStore.permissionList.includes('cmt_create_chatlis_license') || applicationStore.permissionList.includes('cmt_create_fastscreen_license'))
                && (
                  cmtLicensesRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
                )}
              {defaultRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))}
              <Redirect to="/" />
            </Switch>
          </div>
        </Fragment>
      </Router>
    );
  }
}

MainRouter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainRouter);
